import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from '@shared/hooks/useApi';
import BulkAlertUpdateCommand from '@shared/api/queries/Alerts/BulkAlertUpdateCommand';
import { formatDistanceToNowStrict } from 'date-fns';
import { transparentize } from 'polished';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import AlertingRuleActionIcon from './AlertingRuleActionIcon';
import DetailsPanel from './DetailsPanel';
import { Alert, AlertStatus } from '@shared/api/models/Alert/Alert';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { Button } from '@shared/components/atoms/Button/Button';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { TopBarHeight } from '@shared/theme/designConstants';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import NoData from '@shared/components/atoms/NoData/NoData';

interface IAlertList {
  alerts: Alert[];
  refreshAlerts: () => void;
  loading: boolean;
}

const AlertList = ({ alerts, loading, refreshAlerts }: IAlertList) => {
  const onMobile = useMediaQuery('(max-width: 900px)');
  const { execute } = useApi();
  const { trackAction } = useAnalytics();
  const [openAlert, setOpenAlert] = useState<Alert>();
  const [selectedAlerts, setSelectedAlerts] = useState<Alert[]>([]);
  const { t } = useTranslation(['molecules']);

  const acknowledgeAlerts = async () => {
    const ids = selectedAlerts.map((alert) => {
      return alert.id;
    });

    await execute({
      query: new BulkAlertUpdateCommand(ids, AlertStatus.Acknowledged),
    });

    refreshAlerts();
    setSelectedAlerts([]);
  };

  const handleOpenAlert = useCallback((alert: Alert) => {
    if (openAlert?.id === alert.id) {
      setOpenAlert(undefined);
    } else {
      trackAction('view_alert', 'alerts');
      setOpenAlert(alert);
    }
  }, [openAlert, trackAction]);

  /**
   * Returns true when some or all alerts on the current page are selected.
   */
  const someAlertsOnCurrentPageAreSelected = useCallback(() => {
    return alerts.filter(x => x.status === AlertStatus.New).some(x => selectedAlerts.some(a => a.id === x.id));
  }, [alerts, selectedAlerts]);

  /**
   * Returns true when all alerts on the current page are selected.
   */
  const allAlertsOnCurrentPageAreSelected = useCallback(() => {
    return alerts.filter(x => x.status === AlertStatus.New).every(x => selectedAlerts.some(a => a.id === x.id));
  }, [alerts, selectedAlerts]);

  /**
   * Handle mulit-select/deselect in heading column.
   * Deselect all alerts on the current page when some or all alerts on the page were previously selected,
   * select all alerts on the current page when none were previously selected.
   */
  const handleMultiSelect = useCallback(() => {
    if (someAlertsOnCurrentPageAreSelected()) {
      setSelectedAlerts(selected => selected.filter(x => !alerts.some(a => a.id === x.id)));
    } else {
      setSelectedAlerts([...selectedAlerts, ...alerts.filter(x => x.status === AlertStatus.New)]);
    }
  }, [alerts, selectedAlerts, someAlertsOnCurrentPageAreSelected]);

  /**
   * Handle select of a single alert.
   */
  const handleSelect = (alert: Alert, selected: boolean) => {
    if (!selected) {
      setSelectedAlerts(selected => selected.filter(x => x.id !== alert.id));
    } else {
      setSelectedAlerts(selected => [...selected, alert]);
    }
  };

  const getStatusIcon = () => {
    if (!someAlertsOnCurrentPageAreSelected()) {
      return light('circle');
    }

    return allAlertsOnCurrentPageAreSelected() ? solid('circle-check') : regular('circle-minus');
  }

  return (
    <Flex>
      <BulkActionWrapper show={selectedAlerts.length > 0}>
        <Button
          tertiary
          circle
          label={<CloseBulkActionButton icon={solid('xmark')} />}
          onClick={() => setSelectedAlerts([])}
          style={{ padding: '5px' }}
        />

        {selectedAlerts.length} selected

        <div style={{ marginLeft: 'auto' }} />

        <Button
          tertiary
          label={t('Alerting.Acknowledge', { ns: 'molecules' })}
          onClick={acknowledgeAlerts}
        />
      </BulkActionWrapper>

      <List summaryView={!!openAlert} $onMobile={onMobile}>
        <HeaderWrapper>
          <div className='col-1'>
            <StatusIcon
              $newAlert={true}
              $summaryView={!!openAlert || onMobile}
              icon={getStatusIcon()}
              onClick={handleMultiSelect}
            />
          </div>

          <div className={`${!openAlert && !onMobile ? 'col-2' : 'col-10'}`}>
            <TitleLabel>{t('Alerting.RuleName', { ns: 'molecules' })}</TitleLabel>
          </div>

          {!openAlert && !onMobile && (
            <div className='col-2'>
              <TitleLabel>{t('Alerting.Site', { ns: 'molecules' })}</TitleLabel>
            </div>
          )}

          {!openAlert && !onMobile && (
            <div className='col-2'>
              <TitleLabel>{t('Alerting.Building', { ns: 'molecules' })}</TitleLabel>
            </div>
          )}

          {!openAlert && !onMobile && (
            <div className='col-1'>
              <TitleLabel>{t('Alerting.Space', { ns: 'molecules' })}</TitleLabel>
            </div>
          )}

          {!openAlert && !onMobile && (
            <div className='col-2'>
              <TitleLabel>{t('Alerting.AlertedOn', { ns: 'molecules' })}</TitleLabel>
            </div>
          )}

          {!openAlert && !onMobile && (
            <div className='col-1'>
              <TitleLabel>{t('Alerting.Triggers', { ns: 'molecules' })}</TitleLabel>
            </div>
          )}

          {!openAlert && !onMobile && (
            <div
              style={{ display: 'flex' }}
              className='col-1'
            >
              <TitleLabel>{t('Alerting.Actions', { ns: 'molecules' })}</TitleLabel>
            </div>
          )}
        </HeaderWrapper>

        <ScrollContainer>
          {loading &&
            <Loading />
          }

          {!loading && alerts.length === 0 &&
            <NoData
              label={t('NoAlertsFound', { ns: 'status' })}
              styles={{ marginTop: 80 }}
            />
          }

          {!loading && alerts.map((alert, i) => {
            const selected = selectedAlerts.some(x => x.id === alert.id);
            return (
              <ListItem
                key={alert.id}
                alert={alert}
                openAlert={openAlert}
                onSelect={handleOpenAlert}
                summaryView={!!openAlert}
                lastNewAlert={
                  alert.status === AlertStatus.New &&
                  alerts[i + 1] &&
                  alerts[i + 1].status === AlertStatus.Acknowledged
                }
                onMobile={onMobile}
                onAcknowledge={handleSelect}
                selected={selected}
              />
            );
          })}
        </ScrollContainer>
      </List>

      {openAlert && (
        <DetailsPanel
          alert={openAlert}
          close={() => setOpenAlert(undefined)}
          onMobile={onMobile}
        />
      )}

    </Flex>
  );
};

export default AlertList;

const HeaderWrapper = styled.div`
  min-height: 75px;
  max-height: 75px;
  padding: 0 20px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 5px 5px 0 0;
  z-index: 1;
`;

const ScrollContainer = styled.div`
  max-height: ${`calc(100vh - ${TopBarHeight}px - 295px)`};
  min-height: 300px;
  overflow: hidden auto;
`;

const BulkActionWrapper = styled.div<{ show: boolean }>`
  opacity: ${p => p.show ? 1 : 0};
  visibility: ${p => p.show ? 'visible' : 'hidden'};
  transform: ${p => p.show ? 'translateY(-100%)' : 'none'};
  transition: ${p => p.show ? 'all 150ms ease' : 'none'};

  position: absolute;
  top: -10px;
  right: 0px;
  height: 45px;
  width: 300px;
  padding: 0px 12px;

  display: flex;
  align-items: center;
  gap: 5px;

  font-weight: 500;
  color: ${(p) => p.theme.palette.text.fair};
  background-color: ${(p) => p.theme.palette.backgrounds.surface};
  border-radius: 5px;
  box-shadow: 0 0 8px 0px ${(p) => p.theme.palette.shadows.medium};
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const List = styled.div<{ summaryView: boolean; $onMobile: boolean }>`
  display: flex;
  flex-direction: column;

  width: ${(p) => {
    const summaryViewWidth = p.$onMobile ? '0px' : '300px';
    return p.summaryView ? summaryViewWidth : '100%';
  }};
  flex-shrink: 0;
`;

const CloseBulkActionButton = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${p => p.theme.palette.text.fair};
`;

interface IListItem {
  alert: Alert;
  openAlert?: Alert;
  onSelect: (alert: Alert) => void;
  summaryView: boolean;
  lastNewAlert: boolean;
  onMobile: boolean;
  onAcknowledge: (alert: Alert, acknowledged: boolean) => void;
  selected: boolean;
}

const ListItem = React.memo(({ alert, openAlert, onSelect, summaryView, lastNewAlert, onMobile, onAcknowledge, selected }: IListItem) => {
  const getStatusIcon = () => {
    if (alert.status !== AlertStatus.New) {
      return regular('circle-check');
    }

    return selected ? solid('circle-check') : light('circle');
  }

  return (
    <ListItemWrapper
      onClick={() => onSelect(alert)}
      selected={openAlert?.id === alert.id}
      summaryView={summaryView || onMobile}
      newAlert={alert.status === AlertStatus.New}
      hasShadow={lastNewAlert}
    >
      <div className='col-1'>
        <StatusIcon
          $newAlert={alert.status === AlertStatus.New}
          $summaryView={summaryView || onMobile}
          icon={getStatusIcon()}
          onClick={(e) => {
            if (alert.status === AlertStatus.New) {
              e.stopPropagation();
              onAcknowledge(alert, !selected);
            }
          }}
        />
      </div>

      <div className='col-2'>
        <RuleName
          summaryView={summaryView || onMobile}
          isNew={alert.status === AlertStatus.New}
        >
          {alert.message.ruleName}
        </RuleName>

        {!onMobile && (
          <Description summaryView={summaryView || onMobile}>
            {alert.message.description}
          </Description>
        )}
      </div>

      {!summaryView && !onMobile && (
        <div className='col-2'>
          <Label>{alert.space.floor.building.site.name}</Label>
        </div>
      )}

      {!summaryView && !onMobile && (
        <div className='col-2'>
          <Label>{alert.space.floor.building.name}</Label>
        </div>
      )}

      {!summaryView && !onMobile && (
        <div className='col-1'>
          <Label>{alert.space.name}</Label>
        </div>
      )}
      {!summaryView && !onMobile && (
        <div className='col-2'>
          <FlexRow style={{ width: '140px', gap: '10px' }}>
            <CreatedOnIcon icon={regular('alarm-exclamation')} />
            <Label>
              <div>
                {formatDistanceToNowStrict(new Date(alert.createdOn), {
                  addSuffix: true,
                })}
              </div>
            </Label>
          </FlexRow>

          {alert.resolvedOn && (
            <FlexRow
              style={{ width: '140px', gap: '10px', marginTop: '10px' }}
            >
              <AcknowledgedOnIcon icon={regular('circle-check')} />
              <Label>
                <div>
                  {formatDistanceToNowStrict(new Date(alert.resolvedOn), {
                    addSuffix: true,
                  })}
                </div>
              </Label>
            </FlexRow>
          )}
        </div>
      )}

      {!summaryView && !onMobile && (
        <div className='col-1'>
          <DeviceIcon icon={regular('sensor-on')} />
          {alert.message.devices.length > 0 && (
            <DevicesCount>{alert.message.devices.length}</DevicesCount>
          )}
        </div>
      )}

      {!summaryView && !onMobile && (
        <div
          style={{ display: 'flex', gap: '5px' }}
          className='col-1'
        >
          {alert.actions.filter((value, index, array) => array.indexOf(value) === index).map((action, i) => (
            <AlertingRuleActionIcon action={action} key={i} />
          ))}
        </div>
      )}
    </ListItemWrapper>
  );
}
);

const ListItemWrapper = styled.div<{
  selected: boolean;
  newAlert: boolean;
  summaryView: boolean;
  hasShadow: boolean;
}>`
  position: relative;
  min-height: 75px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: ${(p) =>
    p.hasShadow
      ? `0 10px 10px -6px ${p.theme.palette.shadows.medium}`
      : 'none'};
  opacity: ${(p) => (p.newAlert ? 1 : 0.8)};
  background-color: ${(p) =>
    (p.selected && p.theme.palette.backgrounds.surfaceStrong) ||
    (p.newAlert && p.theme.palette.backgrounds.surface) ||
    transparentize(0.8, p.theme.palette.backgrounds.surfaceStrong)};

  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.palette.borders.medium};
  }

  &:hover {
    background-color: ${(p) => p.theme.palette.backgrounds.surfaceStrong};
  }

  display: flex;
  align-items: center;
`;

const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const RuleName = styled.div<{ isNew: boolean; summaryView: boolean }>`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${(p) => (p.isNew ? 500 : 400)};

  ${(p) =>
    p.summaryView &&
    css`
      width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  transition: all 150ms ease;
`;

const Description = styled.div<{ summaryView: boolean }>`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.text.weak};

  ${(p) =>
    p.summaryView &&
    css`
      width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  transition: all 150ms ease;
`;

const DevicesCount = styled.div`
  width: 17px;
  height: 17px;
  border: 2px solid ${(p) => p.theme.palette.primary};
  border-radius: 50%;
  background-color: ${(p) => p.theme.palette.backgrounds.surface};
  color: ${(p) => p.theme.palette.primary};
  font-size: 10px;
  line-height: 11px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -5px;
  left: 25px;
`;

const StatusIcon = styled(FontAwesomeIcon) <{ $newAlert: boolean; $summaryView: boolean; }>`
  color: ${(p) => transparentize(p.$newAlert ? 0 : 0.4, p.theme.palette.primary)};
  margin-left: ${(p) => (p.$summaryView ? '-8px' : '20px')};
  font-size: 20px;
  margin-top: 4px;
  cursor: pointer;
`;

const DeviceIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(p) => transparentize(0.2, p.theme.palette.text.fair)};
  margin-top: 4px;
`;

const TitleLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${(p) => transparentize(0, p.theme.palette.text.fair)};
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${(p) => transparentize(0.2, p.theme.palette.text.fair)};
`;

const CreatedOnIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(p) => transparentize(0.1, p.theme.palette.red)};
`;

const AcknowledgedOnIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(p) => transparentize(0, p.theme.palette.primary)};
`;
