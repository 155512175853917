import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertRuleActionType } from '@shared/api/models/AlertRule/AlertRule';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

interface IAlertingRuleActionIcon {
  action: AlertRuleActionType
}

const AlertingRuleActionIcon = ({ action }: IAlertingRuleActionIcon) => {
  const theme = useTheme();
  const { t } = useTranslation(['molecules']);

  const getIcon = (action: AlertRuleActionType): IconDefinition => {
    switch (action) {
      case AlertRuleActionType.Email:
        return regular('envelope');
      case AlertRuleActionType.EmailDigest:
        return regular('inbox');
      case AlertRuleActionType.Webhook:
        return regular('link-horizontal');
      case AlertRuleActionType.SignalR:
        return regular('browser');
      default:
        return regular('envelope');
    }
  };

  const getLabel = (action: AlertRuleActionType): AlertRuleActionType | string =>
    action === AlertRuleActionType.SignalR ? t('Alerting.InAppNotification', { ns: 'molecules' }) : action;

  return (
    <Tooltip
      content={getLabel(action)}
      placement={TooltipPlacement.Top}
      distance={0}
      fontSize='12px'
      hideTriangle={true}
      tooltipColor={transparentize(0.15, theme.palette.tooltip.background.primary)}
    >
      <ActionIcon icon={getIcon(action)} />
    </Tooltip>
  );
};

export default AlertingRuleActionIcon;

const ActionIcon = styled(FontAwesomeIcon)`
  font-size: 17px;
  color: ${p => transparentize(0.2, p.theme.palette.text.fair)};
`;