import { AlertCountBySiteGetQuery } from '@dashboard/api/queries/Alerts/AlertCountBySiteGetQuery';
import { AlertsGetBySiteQuery } from '@dashboard/api/queries/Alerts/AlertsGetBySiteQuery';
import AlertList from '@dashboard/components/molecules/AlertList/AlertList';
import { Button } from '@shared/components/atoms/Button/Button';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import { Paging } from '@shared/components/molecules/Table/Paging';
import { useApiState } from '@shared/hooks/useApiState';
import { orderBy } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSiteContext } from '../SitePage/SiteProvider';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';

const pageSizeOptions = [
  { label: 'Show 10', value: 10 },
  { label: 'Show 20', value: 20 },
  { label: 'Show 50', value: 50 }
];

const AlertsPage = () => {
  const { t } = useTranslation(['common'])
  const { site } = useSiteContext();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { data: alertCount, execute: refreshAlertCount } = useApiState({
    query: new AlertCountBySiteGetQuery(site.id),
    initialState: 0,
  }, []);

  const { transform: alerts, loading, execute: refreshAlerts } = useApiState({
    query: new AlertsGetBySiteQuery(site.id, page, pageSize),
    transformFunc: alerts => orderBy(alerts, [
      x => x.status,
      x => new Date(x.createdOn),
      x => x.message.ruleName.toLocaleLowerCase()
    ], ['desc', 'desc', 'asc']),
    initialTransformState: [],
  }, [site, page, pageSize]);

  const handleRefresh = useCallback(() => {
    refreshAlertCount();
    refreshAlerts();
  }, [refreshAlertCount, refreshAlerts]);

  return (
    <FullWidthContainer>
      <PaddedContainer>
        <FlexRow>
          <Title
            size='lg'
            text={t('AlertsPageTitle', { ns: 'common' })}
          />

          <ProtectedLink link={{ path: 'rules', analytics: { action: 'view_alert_rules', category: 'alerts' } }}>
            <Button
              secondary
              label={t('ManageAlertRules', { ns: 'common' })}
            />
          </ProtectedLink>
        </FlexRow>

        <Card noPadding>
          <AlertList
            alerts={alerts}
            loading={loading}
            refreshAlerts={handleRefresh}
          />
        </Card>

        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '15px' }}>
          <Paging
            numItems={alertCount}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      </PaddedContainer>
    </FullWidthContainer>
  );
};

export default AlertsPage;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;