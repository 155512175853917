import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const UtilitiesNoData = () => {
  const { t } = useTranslation();

  return (
    <FlexColumn>
      <Icon
        icon={duotone('chart-simple')}
      />

      <Label>
        {t('NoDataYet', { ns: 'status' })}
      </Label>
    </FlexColumn>
  );
};

export default UtilitiesNoData;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 80px 32px 80px 32px;
  border-radius: 5px;
  background-color: ${(p) => p.theme.palette.backgrounds.surface};
  box-shadow: 0px 3px 8px ${(p) => p.theme.palette.shadows.medium};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 58px;
  height: 58px;
  color: #FFB500;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.medium};
`;