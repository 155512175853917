import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import AlertsPageV2 from './AlertsPageV2';
import AlertsPage from './AlertsPage';

const AlertsPageVersion = () => {
  const { hasFeatureFlag } = useTenantContext();

  if (hasFeatureFlag(FeatureFlag.AlertingV2)) {
    return <AlertsPageV2 />;
  }

  return <AlertsPage />
}

export default AlertsPageVersion;