import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { RouteLink } from '@shared/types/Route';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { DoughnutChartDataset } from './DoughnutChart';

const renderLabelOrLink = (item: LegendItem) => {
  if (item.link) {
    return (
      <ItemLink link={item.link}>
        <ColoredDot color={item.color} />

        <HoverLabel>
          {item.label}
        </HoverLabel>
      </ItemLink>
    );
  }

  return (
    <Item>
      <ColoredDot color={item.color} />

      <Label>
        {item.label}
      </Label>
    </Item>
  );
}

type LegendItem = {
  label: ReactNode;
  color: string;
  link?: RouteLink;
}

export type TDoughnutChartLegend = {
  items?: LegendItem[];
  title?: ReactNode;
  maxHeight?: number;
}

type PropTypes = {
  legend?: TDoughnutChartLegend;
  dataset: DoughnutChartDataset;
}

const DoughnutChartLegend = ({ legend, dataset }: PropTypes) => {

  if (!legend) {
    return null;
  }

  const items: LegendItem[] = legend.items ?? (dataset.labels?.map((label, i) => ({ label: label, color: dataset.colors[i] })) ?? []);

  return (
    <Legend maxHeight={legend.maxHeight}>
      {legend.title &&
        <Title>
          {legend.title}
        </Title>
      }

      {items.map((item) => (
        <ItemWrapper key={uuidv4()}>
          {renderLabelOrLink(item)}
        </ItemWrapper>
      ))}
    </Legend>
  );
};

export default DoughnutChartLegend;

const Legend = styled.div<{ maxHeight?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  max-height: ${p => p.maxHeight ? `${p.maxHeight}px` : 'none'};
  overflow-x: auto;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 6px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemWrapper = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ItemLink = styled(ProtectedLink)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ColoredDot = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${p => p.color};
`;

const Label = styled.div`
  padding: 2.5px 5px;
  border-radius: 4px;
  white-space: nowrap;
`;

const HoverLabel = styled(Label)`
  ${ItemWrapper}:hover & {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.background};
  }
`;