import { EmailSendCommand } from '@shared/api/queries/Email/EmailSendCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { env } from '@shared/env/env';
import { useApi } from '@shared/hooks/useApi';
import Cookie from 'js-cookie';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

export const DemoRequestedCookie = 'demo-requested';

type PropTypes = {
  feature: string;
}

const DemoButton = ({ feature }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute, loading } = useApi();
  const { user } = useUserContext();
  const { tenant } = useTenantContext();
  const [demoRequested, setDemoRequested] = useState(false);
  const cookieName = `${DemoRequestedCookie}-${feature}`;
  const demoPreviouslyRequested = !!Cookie.get(cookieName);

  const handleDemoRequest = () => {
    const paragraph1 = `${user.fullName} in the ${tenant.name} tenant has requested a demo of the ${feature} feature on Utopi Connect.`;
    const paragraph3 = `Please contact ${user.fullName} via their email ${user.email} to arrange a demo.`;

    execute({
      query: new EmailSendCommand(env.emails.contact, 'Demo Request', `${feature} - Demo Request`, [paragraph1, paragraph3], feature, 'Demo Request')
    })

    Cookie.set(cookieName, 'true', {
      sameSite: 'strict',
      expires: 7,
    });

    setDemoRequested(true);
  }

  return (
    <Container>
      {demoRequested &&
        <Confirmation>
          {t('DemoRequestConfirmation', { ns: 'status' })}
        </Confirmation>
      }

      {!demoRequested &&
        <FlexRow>
          <Button
            label={t('RequestADemo', { ns: 'status' })}
            onClick={handleDemoRequest}
            loading={loading}
            disabled={demoPreviouslyRequested}
            style={demoPreviouslyRequested ? { backgroundColor: theme.previewBanner.disabledButtonBackground, color: theme.previewBanner.disabledButtonText } : {}}
          />

          {demoPreviouslyRequested &&
            <ConfirmationLabel>
              {t('RecentDemoRequestMessage', { ns: 'status' })}
            </ConfirmationLabel>
          }
        </FlexRow>
      }
    </Container>
  );
};

export default DemoButton;

const Container = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Confirmation = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;

const ConfirmationLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;